<template>
  <div class="content">
    <div class="content-box">
      <Location :locationMsg="locationMsg"></Location>
      <div class="line"></div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
import Location from "./Location.vue";

export default {
  props: ["locationMsg"],
  data() {
    return {};
  },
  methods: {},
  components: { Location },
};
</script>
<style scoped lang="scss">
.content {
  display: flex;
  justify-content: center;
  width: 85%;
  margin: 0 auto;
  background: #eceef1;
  padding: 20px 0 80px 0px;
  flex: 1;
  min-height:768px;
}

.content-box {
  width: 70%;
  height: 100%;
  // min-height: 500px;
  flex-wrap: wrap;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 9px 3px rgba(204, 221, 244, 0.5);
  border-radius: 20px;
  filter: blur(0px);
  .line {
    width: 100%;
    height: 1px;
    background: #d8d8d8;
    margin-top: 15px;
  }
}
// @media screen and (max-width: 992px) {
//   .imageTextLeft {
//     width: 100%;
//   }
// }
</style>