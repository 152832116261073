<template>
  <ContainerCard :locationMsg="locationMsg">
    <div class="ApplyForIndustry">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="200px"
        class="demo-ruleForm"
      >
        <h2>申请成为产教融合性企业</h2>
        <el-form-item label="申请单位名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入申请单位名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业logo" prop="name">
          <el-upload
            class="avatar-uploader"
            action="https://jsonplaceholder.typicode.com/posts/"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="行业" prop="tech_field">
          <el-input v-model="ruleForm.name" placeholder="请输入行业"></el-input>
        </el-form-item>

        <el-form-item label="联系地址" prop="address">
          <el-cascader
            size="large"
            :options="options"
            v-model="ruleForm.address"
            @change="handleChange"
            clearable
            style="margin-bottom: 20px"
          >
          </el-cascader>
          <el-input v-model="addressDetail" placeholder="请输入具体地址">
          </el-input>
        </el-form-item>

        <el-form-item label="企业法人" prop="company">
          <el-input
            v-model="ruleForm.company"
            placeholder="请输入企业法人"
          ></el-input>
        </el-form-item>

        <el-form-item label="统一社会信用代码" prop="contact">
          <el-input
            v-model="ruleForm.contact"
            placeholder="请输入统一社会信用代码"
          ></el-input>
        </el-form-item>

        <el-form-item label="社会信用等级" prop="mobile">
          <el-input
            v-model="ruleForm.mobile"
            placeholder="请输入社会信用等级"
          ></el-input>
        </el-form-item>

        <el-form-item label="联系人" prop="memo">
          <el-input
            :rows="12"
            v-model="ruleForm.memo"
            placeholder="请输入联系人"
          ></el-input>
        </el-form-item>

        <el-form-item label="联系电话" prop="memo">
          <el-input
            :rows="12"
            v-model="ruleForm.memo"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>

        <el-form-item label="邮箱" prop="memo">
          <el-input
            :rows="12"
            v-model="ruleForm.memo"
            placeholder="请输入邮箱"
          ></el-input>
        </el-form-item>

        <el-form-item label="上一年度吸纳就业人数" prop="memo">
          <el-input
            :rows="12"
            v-model="ruleForm.memo"
            placeholder="请输入上一年度吸纳就业人数"
          ></el-input>
        </el-form-item>

        <el-form-item label="上一年度研发经费（元）" prop="memo">
          <el-input
            :rows="12"
            v-model="ruleForm.memo"
            placeholder="请输入上一年度研发经费（元）"
          ></el-input>
        </el-form-item>

        <el-form-item label="上一年度职工教育经费（元）" prop="memo">
          <el-input
            :rows="12"
            v-model="ruleForm.memo"
            placeholder="上一年度职工教育经费（元）"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业简介" prop="memo">
          <el-input
            type="textarea"
            :rows="12"
            v-model="ruleForm.memo"
            placeholder="企业简介"
          ></el-input>
        </el-form-item>

        <el-form-item class="subContainer">
          <el-button class="submitBtn btn" @click="submitForm('ruleForm')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </ContainerCard>
</template>
<script>
import Location from "../components/Location.vue";
import { regionData, CodeToText } from "element-china-area-data";
import ContainerCard from "../components/ContainerCard.vue";
import { mapGetters } from "vuex";
import { objDeepCopy } from "../common/utils/tool.js";
import { publishDemand } from "../api/demand/demand.js";
export default {
  computed: {
    ...mapGetters(["techfieldList"]),
  },
  data() {
    return {
      ruleForm: {
        name: "", // 需求名称
        tech_field: [], //技术领域
        company: "", // 单位名称
        address: "", // 地址
        contact: "", //联系人
        mobile: "", // 联系电话
        imageUrl: "",
        memo: "", // 需求描述
      },
      addressDetail: "",
      options: regionData,
      rules: {
        name: [
          { required: true, message: "请输入需求名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        company: [
          { required: true, message: "请输入单位名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        tech_field: [
          { required: true, message: "请选择技术领域", trigger: "blur" },
        ],
        address: [{ required: false, message: "请输入地址", trigger: "blur" }],
        contact: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        mobile: [{ required: true, message: "请输入电话", trigger: "blur" }],
        memo: [{ required: true, message: "请输入简介", trigger: "blur" }],
        // desc: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
      },
      locationMsg: [
        { name: "申请成为产教融合性企业", router: "ApplyForIndustry" },
      ],
    };
  },
  created() {},
  methods: {
    submitForm(formName) {
      let that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          let totalAddress = [];
          let address = that.ruleForm.address;
          let params = objDeepCopy(that.ruleForm);
          params.tech_field = that.ruleForm.tech_field.join(",");
          address &&
            address.forEach((item) => {
              totalAddress.push(CodeToText[item]);
            });
          totalAddress.push(that.addressDetail);
          let addr = totalAddress.join(",");
          params.address = addr;
          params.address = encodeURI(addr);
          // console.log("form", params);
          that.submitRequest(params);
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    async submitRequest(params) {
      let res = await publishDemand(params);
      if (res.success) {
        message.success("发布成功");
        this.$router.go(-1);
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    handleChange(value) {
      // console.log(value);
    },
  },
  components: {  Location, ContainerCard },
};
</script>
<style scoped lang="scss">
.ApplyForIndustry {
  width: 100%;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #101010;
  margin-top: 20px;

 
}
.el-button:focus,
.el-button:hover {
  background: #14569f;
  border-radius: 4px;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
}
.el-form {
  width: 80%;
  margin: 0 auto;
}
h2 {
  text-align: center;
  font-weight: normal;
  font-size: 28px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #101010;
  margin-bottom: 20px;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.subContainer {
  // display: ;
  text-align: center;
}
.submitBtn {
  width: 180px;
  height: 42px;
  background: #14569f;
  border-radius: 4px;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
}
</style>

<style>
@media screen and (max-width: 768px) {
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>